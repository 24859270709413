import React from 'react';
import styled from 'styled-components';
import { spacing, fontStyle, breakpoints } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Text } from '@naf/text';

export const MemberCard = () => {
  const bodyText =
    'Medlemsbeviset er alltid tilgjengelig i appen. Plastkortet er historie! Nesten.. det trengs fortsatt for å få drivstoffrabatten hos Circle K, med mindre du har registrert betalingskortet ditt i Circle K Extra Club.';
  return (
    <Container>
      <Body>
        <Header variant="header2">Digitalt medlemsbevis</Header>
        {bodyText}
      </Body>
      <Card>
        <img src="/resources/medlemsbevis.svg" alt="Medlemskort" />
      </Card>
    </Container>
  );
};

export default MemberCard;

const Container = styled.div`
  background-color: ${nafColor.primary.dew};
  display: flex;
  padding: ${spacing.space160} ${spacing.space40} ${spacing.space64} ${spacing.space80};

  @media (max-width: ${breakpoints.l}) {
    align-items: center;
  }

  @media (max-width: ${breakpoints.m}) {
    flex-flow: column-reverse;
    align-items: flex-start;
    padding: ${spacing.space48} ${spacing.space24} ${spacing.space64};
  }
`;

const Body = styled.div`
  width: calc(50vw - 48px);
  margin-left: ${spacing.space48};
  margin-right: ${spacing.space48};
  padding-right: ${spacing.space48};
  ${fontStyle.bodyLarge};

  @media (max-width: ${breakpoints.m}) {
    width: calc(100% - 2 * ${spacing.space24});
    margin: 0;
  }
`;

const Header = styled(Text)`
  margin-bottom: ${spacing.space16};

  @media (max-width: ${breakpoints.m}) {
    margin: ${spacing.space40} 0 ${spacing.space24};
    font-size: 1.75rem;
  }
`;

const Card = styled.div`
  max-width: 352px;

  img {
    width: 100%;
  }
`;
