import React from 'react';
import styled from 'styled-components';
import DownloadLinks from './DownloadLinks';
import { nafColor } from '@nafcore/theme';
import { spacing, fontStyle, breakpoints } from '@naf/theme';
import { Text } from '@naf/text';
import { ButtonLink } from '@naf/button-link';

const Footer = () => {
  return (
    <Container>
      <Header variant="header1">Vel frem</Header>
      <Body variant="body">Har du spørsmål om appen, ta kontakt med oss på</Body>
      <ButtonLink href="mailto:medlemsapp@naf.no" variant="negative" text="medlemsapp@naf.no" />
      <DownloadApp negative />
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  background-color: ${nafColor.primary.spruce};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${spacing.space64} ${spacing.space24} ${spacing.space160};
  color: ${nafColor.signature.white};
`;

const Header = styled(Text)`
  margin-bottom: ${spacing.space8};
  margin-top: ${spacing.space8};

  @media (max-width: ${breakpoints.m}) {
    margin-bottom: ${spacing.space8};
    font-size: 2rem;
  }
`;

const Body = styled(Text)`
  margin-top: 0;
  margin-bottom: ${spacing.space16};

  @media (max-width: ${breakpoints.m}) {
    ${fontStyle.details};
    margin-bottom: ${spacing.space16};
  }
`;

const DownloadApp = styled(DownloadLinks)`
  display: flex;
  justify-content: center;
  margin-top: ${spacing.space32};
`;
