import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { spacing, breakpoints, fontStyle } from '@naf/theme';
import { Text } from '@naf/text';
import { TextList, TextListItem } from '@naf/text-list';
import { nafColor } from '@nafcore/theme';
import DownloadLinks from './DownloadLinks';

export const Membership = () => {
  const heroRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);
  const BodyList = () => {
    return (
      <>
        <TextList variant="check">
          <TextListItem>Vise medlemsbeviset når du trenger det.</TextListItem>
          <TextListItem>Få oversikt over medlemsfordeler og spare penger langs veien.</TextListItem>
          <TextListItem>Bestille veihjelp rett i appen.</TextListItem>
          <TextListItem>Få nøkkelinformasjon om dine kjøretøy.</TextListItem>
          <TextListItem>Se historikk fra NAF og NAF Senter.</TextListItem>
        </TextList>
        <ResponsiveText variant="bodyBold">Du kan også:</ResponsiveText>
        <TextList variant="check">
          <TextListItem>
            Booke time på NAF Senter og bli påminnet om tid og endre time.
          </TextListItem>
          <TextListItem>Bruke digital kjøpekontrakt når du selge og kjøpe bil.</TextListItem>
          <TextListItem>Bruke digital skademelding når uhellet er ute.</TextListItem>
          <TextListItem>Få melding når EU-kontrollen nærmer seg.</TextListItem>
          <TextListItem>er du ikke medlem, kan du raskt melde deg inn.</TextListItem>
        </TextList>
      </>
    );
  };

  const setSpacing = useCallback(() => {
    if (heroRef.current) {
      setHeight(heroRef.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setSpacing);
    window.addEventListener('load', setSpacing);

    return () => {
      window.removeEventListener('resize', setSpacing);
      window.removeEventListener('load', setSpacing);
    };
  }, [setSpacing]);

  return (
    <Container>
      <Top>
        <Hero ref={heroRef}>
          <Display variant="display">
            Få mer ut av <NoBreak>NAF-medlem&shy;skapet</NoBreak>
          </Display>
        </Hero>
        <Intro variant="intro" top={height}>
          Last ned NAF Appen i dag!
        </Intro>
        <DownloadLinks />
      </Top>
      <Body>
        <Content>
          <ResponsiveText variant="bodyBold">
            Med NAF Appen har du NAF-medlemskapet i lomma. Du kan:
          </ResponsiveText>
          <BodyList />
        </Content>
        <Image>
          <img src="/resources/iphone.png" alt="Skjermbilde av appen" />
        </Image>
      </Body>
    </Container>
  );
};

export default Membership;

const Container = styled.div`
  margin-left: ${spacing.space80};
  margin-bottom: ${spacing.space56};
  display: flex;
  flex-direction: column;

  > div {
    margin-right: ${spacing.space48};
    margin-left: ${spacing.space48};
  }

  @media (max-width: ${breakpoints.l}) {
    margin-left: ${spacing.space12};
    margin-right: ${spacing.space12};

    > div {
      margin-left: ${spacing.space12};
      margin-right: ${spacing.space12};
    }
  }
`;

const Top = styled.div`
  width: 50vw;
  position: relative;
  margin-bottom: ${spacing.space16};

  @media (max-width: ${breakpoints.l}) {
    width: 100%;
    margin-bottom: 0;
  }
`;

const Hero = styled.div`
  position: absolute;
  width: 50vw;
  top: calc(-${spacing.space64} - ${spacing.space40});
  left: -${spacing.space48};
  padding: ${spacing.space48};
  padding-bottom: 0;
  background-color: ${nafColor.signature.white};

  @media (min-width: ${breakpoints.l}) {
    min-width: 600px;
  }

  @media (max-width: ${breakpoints.l}) {
    width: calc(100% - 2 * ${spacing.space12});
    top: -45px;
    left: -${spacing.space12};
    right: ${spacing.space12};
    padding: ${spacing.space12};
    padding-bottom: 0;
  }
`;

const Display = styled(Text)`
  margin: 0;

  @media (max-width: ${breakpoints.m}) {
    font-size: 2.5rem;
    line-height: 1;
  }
`;

const NoBreak = styled.span`
  white-space: pre;

  @media (max-width: ${breakpoints.l}) {
    white-space: initial;
  }
`;

const Body = styled.div`
  display: flex;
  margin-top: ${spacing.space80};

  @media (max-width: ${breakpoints.l}) {
    flex-direction: column-reverse;
    margin-top: ${spacing.space64};
  }
`;

const Content = styled.div`
  width: 50vw;
  margin-right: ${spacing.space48};

  @media (max-width: ${breakpoints.l}) {
    width: calc(100% - 2 * ${spacing.space12});
    margin: 0;
  }
`;

const Intro = styled(Text)<{ top: number }>`
  margin-top: calc(${(props) => props.top}px - ${spacing.space80});
  margin-bottom: ${spacing.space40};

  @media (max-width: ${breakpoints.l}) {
    margin-top: calc(${(props) => props.top}px - ${spacing.space24});
    margin-bottom: ${spacing.space32};
    font-size: 1.25rem;
  }
`;

const ResponsiveText = styled(Text)`
  @media (max-width: ${breakpoints.m}) {
    ${fontStyle.details};
  }
`;

const Image = styled.div`
  max-width: 408px;
  img {
    width: 100%;
    margin-bottom: ${spacing.space40};
  }
`;
