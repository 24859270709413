import React from 'react';
import styled from 'styled-components';
import { spacing, fontStyle, breakpoints } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import NafLogo from './NafLogo';

export const Header = () => {
  return (
    <Container>
      <Banner>
        <NafLogo />
        <RoadAssistance>
          <BannerText>Veihjelp</BannerText>
          <BannerNr>08 505</BannerNr>
        </RoadAssistance>
      </Banner>
      <img src="/resources/trollstigen.jpg" alt="Trollstigen" />
    </Container>
  );
};

export default Header;

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 80vh;

  img {
    object-position: 0 40%;
    width: 100%;
    object-fit: cover;

    @media (max-width: ${breakpoints.m}) {
      object-position: 50% 0;
    }
  }
`;

const Banner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${spacing.space120};
  background-color: ${nafColor.signature.white};
  display: flex;
  align-items: center;
  padding-left: ${spacing.space160};

  @media (max-width: ${breakpoints.xxl}) {
    height: ${spacing.space80};
  }

  @media (max-width: ${breakpoints.l}) {
    height: ${spacing.space64};
    padding-left: ${spacing.space24};

    svg {
      height: ${spacing.space48};
      width: ${spacing.space48};
    }
  }
`;

const RoadAssistance = styled.span`
  margin-left: ${spacing.space32};
  display: inline-flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.m}) {
    margin-left: ${spacing.space16};
    > p {
      font-size: 1rem;
    }
  }
`;

const BannerText = styled.p`
  ${fontStyle.bodyLarge};
  font-weight: 400;
  margin: 0;
`;

const BannerNr = styled.p`
  ${fontStyle.bodyLarge};
  font-weight: 700;
  margin: 0;
`;
