import React from 'react';
import Header from '../components/Header';
import Membership from '../components/Membershipapp';
import Membercard from '../components/Membershipcard';

const Home = () => {
  return (
    <>
      <Header />
      <Membership />
      <Membercard />
    </>
  );
};

export default Home;
