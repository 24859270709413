import React from 'react';
import styled from 'styled-components';
import { spacing, radius } from '@naf/theme';
import { useApplicationState } from '../ApplicationState';

import { createIosDownloadLink, createAndroidDownloadLink } from '../appstoreLinks';

interface DownloadProps {
  negative?: boolean;
  className?: any;
}

const DownloadLinks = ({ negative = false, className }: DownloadProps) => {
  const { queryParameters } = useApplicationState();
  return (
    <DownloadOptions className={className}>
      <Option>
        <Link href={createIosDownloadLink(queryParameters)} target="_blank">
          <Image
            src={negative ? '/resources/apple-store-white.svg' : '/resources/apple-store.svg'}
            alt="App Store Logo"
          />
        </Link>
      </Option>
      <Option>
        <Link href={createAndroidDownloadLink(queryParameters)} target="_blank">
          <Image
            src={negative ? '/resources/google-play-white.svg' : '/resources/google-play.svg'}
            alt="Google Play Logo"
          />
        </Link>
      </Option>
    </DownloadOptions>
  );
};

export default DownloadLinks;

const Link = styled.a`
  display: flex;
`;

const DownloadOptions = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 380px) {
    flex-direction: column;
    margin: 0;
    align-items: center;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 45px;
  object-fit: contain;
  border-radius: ${radius.m};
`;

const Option = styled.div`
  &:first-child {
    margin-right: ${spacing.space8};

    @media (max-width: 380px) {
      margin-right: 0px;
      margin-bottom: ${spacing.space8};
    }
  }
`;
