const fallbackCampaignToken = 'medlemsapp_no_campaign';
const fallbackMediumToken = 'medlemsapp_no_medium';
const iosProviderId = '591944';
const trackingSource = 'nafmedlemweb';

const getCampaignString = (queryParameters: any) =>
  queryParameters.utm_campaign || fallbackCampaignToken;

const getMediumString = (queryParameters: any) => queryParameters.utm_medium || fallbackMediumToken;

export const createIosDownloadLink = (queryParameters: any) =>
  `https://apps.apple.com/no/app/naf-medlem/id1486156324?pt=${iosProviderId}&ct=${encodeURIComponent(
    getCampaignString(queryParameters)
  )}&mt=8&utm_medium=${encodeURIComponent(
    getMediumString(queryParameters)
  )}&utm_source=${trackingSource}`;

export const createAndroidDownloadLink = (queryParameters: any) =>
  `https://play.google.com/store/apps/details?id=no.naf.medlem&hl=en&utm_source=${trackingSource}&utm_campaign=${encodeURIComponent(
    getCampaignString(queryParameters)
  )}&utm_medium=${encodeURIComponent(getMediumString(queryParameters))}`;
